/**
* Template Name: Techie - v2.1.0
* Template URL: https://bootstrapmade.com/techie-free-skin-bootstrap-3/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
!(function($) {
  "use strict";

  // Preloader
  $(window).on('load', function() {
    if ($('#preloader').length) {
      $('#preloader').delay(100).fadeOut('slow', function() {
        $(this).remove();
      });
    }
  });

  // Smooth scroll for the navigation menu and links with .scrollto classes
  var scrolltoOffset = $('#header').outerHeight() - 16;
  if (window.matchMedia("(max-width: 991px)").matches) {
    scrolltoOffset += 16;
  }
  $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function(e) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      e.preventDefault();
      var target = $(this.hash);
      if (target.length) {

        var scrollto = target.offset().top - scrolltoOffset;

        if ($(this).attr("href") == '#header') {
          scrollto = 0;
        }

        $('html, body').animate({
          scrollTop: scrollto
        }, 1500, 'easeInOutExpo');

        if ($(this).parents('.nav-menu, .mobile-nav').length) {
          $('.nav-menu .active, .mobile-nav .active').removeClass('active');
          $(this).closest('li').addClass('active');
        }

        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
          $('.mobile-nav-overly').fadeOut();
        }
        return false;
      }
    }
  });

  // Activate smooth scroll on page load with hash links in the url
  $(document).ready(function() {
    if (window.location.hash) {
      var initial_nav = window.location.hash;
      if ($(initial_nav).length) {
        var scrollto = $(initial_nav).offset().top - scrolltoOffset;
        $('html, body').animate({
          scrollTop: scrollto
        }, 1500, 'easeInOutExpo');
      }
    }
  });

  // Mobile Navigation
  if ($('.nav-menu').length) {
    var $mobile_nav = $('.nav-menu').clone().prop({
      class: 'mobile-nav d-lg-none'
    });
    $('body').append($mobile_nav);
    $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
    $('body').append('<div class="mobile-nav-overly"></div>');

    $(document).on('click', '.mobile-nav-toggle', function(e) {
      $('body').toggleClass('mobile-nav-active');
      $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
      $('.mobile-nav-overly').toggle();
    });

    $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
      e.preventDefault();
      $(this).next().slideToggle(300);
      $(this).parent().toggleClass('active');
    });

    $(document).click(function(e) {
      var container = $(".mobile-nav, .mobile-nav-toggle");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
          $('.mobile-nav-overly').fadeOut();
        }
      }
    });
  } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
    $(".mobile-nav, .mobile-nav-toggle").hide();
  }

  // Navigation active state on scroll
  var nav_sections = $('section');
  var main_nav = $('.nav-menu, #mobile-nav');

  $(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop() + 200;

    nav_sections.each(function() {
      var top = $(this).offset().top,
        bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        if (cur_pos <= bottom) {
          main_nav.find('li').removeClass('active');
        }
        main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
      }
      if (cur_pos < 300) {
        $(".nav-menu ul:first li:first").addClass('active');
      }
    });
  });

  // Toggle .header-scrolled class to #header when page is scrolled
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    } else {
      $('#header').removeClass('header-scrolled');
    }
  });

  if ($(window).scrollTop() > 100) {
    $('#header').addClass('header-scrolled');
  }

  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });

  $('.back-to-top').click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, 1500, 'easeInOutExpo');
    return false;
  });

  // jQuery counterUp
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 1000
  });

  // Testimonials carousel (uses the Owl Carousel library)
  $(".testimonials-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      900: {
        items: 3
      }
    }
  });

  // Porfolio isotope and filter
  $(window).on('load', function() {
    var portfolioIsotope = $('.portfolio-container').isotope({
      itemSelector: '.portfolio-item'
    });

    $('#portfolio-flters li').on('click', function() {
      $("#portfolio-flters li").removeClass('filter-active');
      $(this).addClass('filter-active');

      portfolioIsotope.isotope({
        filter: $(this).data('filter')
      });
      aos_init();
    });

    // Initiate venobox (lightbox feature used in portofilo)
    $(document).ready(function() {
      $('.venobox').venobox();
    });
  });

  // Portfolio details carousel
  $(".portfolio-details-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    items: 1
  });

  // Init AOS
  function aos_init() {
    AOS.init({
      duration: 1000,
      once: true
    });
  }
  $(window).on('load', function() {
    aos_init();
  });

})(jQuery);


//javascript
(function() {
  "use strict";

  window.onload = function(){
    //move any customizations of options to the custom field
    for (let el of document.querySelectorAll('.form-check-label')){
      el.addEventListener('click', async function(){
        el.checked = el.checked === undefined ? true : !el.checked;
        await setTimeout(function(){ 
          document.getElementById('customBtn').click();
        }, 100);        
      });
    }
  }  


  //pricing selection
  document.querySelectorAll('#pricingButtons button').forEach(el => el.addEventListener('click', async function() {
    let prevSelected = document.querySelector('button[selected]');
    prevSelected.removeAttribute('selected');

    let nowSelected = this;
    nowSelected.setAttribute('selected', '');

    let activeOptions = [];
    let multiplier = 1;
    switch(nowSelected.id) {
      case 'essentialsBtn':
        multiplier = .8;
        activeOptions = ['inlineCheckbox1', 'inlineCheckbox3'];
        break;
      case 'deluxeBtn':
        multiplier = .75;
        activeOptions = ['inlineCheckbox1', 'inlineCheckbox2', 'inlineCheckbox3'];
        break;
      case 'premierBtn':
        multiplier = .7;
        activeOptions = ['inlineCheckbox1', 'inlineCheckbox2', 'inlineCheckbox3', 'inlineCheckbox4', 'inlineCheckbox5', 'inlineCheckbox6', 'inlineCheckbox7'];
        break;
      case 'customBtn':
        //convert all checked boxes into active options
        multiplier = 1;
        activeOptions = Array.from(document.querySelectorAll('input[type=checkbox]:checked')).map(el => el.id);
        break;
      default:
        break;
    }

    for (el of document.querySelectorAll('input[type=checkbox]')){
      el.checked = activeOptions.includes(el.id) ? true : false
    }

    let cost = 0;
    document.querySelectorAll('input[type=checkbox]').forEach(function(el){
      let priceValue = document.getElementById('squareFootage').value > 1500 ? 
        parseInt(el.value) + parseInt(Math.ceil((document.getElementById('squareFootage').value - 1500)/1000) * 50) 
        : el.value;
      cost += el.checked ? parseInt(priceValue) : 0;
    })

    console.log(cost);
    let priceQuote = roundPrice(cost * multiplier);
    document.getElementById('quoteValue').innerText = "$" + priceQuote;


  //   console.log(this)
  //   let prevSelected = document.querySelector('button[selected]');
  //   prevSelected.style.animation='fadeOut 1s 1';
  //   prevSelected.style.backgroundColor = '#5846f9';
  //   setTimeout(function(){     
  //     prevSelected.style.animation=''; 
  //     prevSelected.style.backgroundColor = '#FFF';  
  //   },1000);

  //   //run animation time, wait until animation is done to continue
  //   // await new Promise((resolve, reject) => {
  //   //   document.querySelector('button[selected]').style.animation='fadeOut .5s 1';
  //   //   setTimeout(function(){     
  //   //     document.querySelector('button[selected]').style.animation='';   
  //   //     resolve(true);
  //   //   },1000);
  //   // });
    
  //   document.querySelector('button[selected]').removeAttribute('selected');
  //   let thisEl = this;
  //   thisEl.setAttribute('selected', '');
  //   thisEl.style.animation='fadeIn 1s 1';
  //   setTimeout(function(thisEl){     
  //     thisEl.style.animation='';
  //     prevSelected.style.backgroundColor = '#5846f9';     
  //   },1000);
  }));

  function roundPrice(x) {
    if (x < 300) {
      return 300;
    } 
    else if (x%50 < 25) {
        return x - (x%50) - 1; 
    }
    else if (x%50 > 25) {
        return x + (50 - (x%50)) - 1; 
    }
    else if (x%50 == 25) {
        return x + 24; //when it is halfawy between the nearest 50 it will automatically round up, change this line to 'return x - 25' if you want it to automatically round down
    }        
}

  function setRadioSelected(){}

})();